export enum loginErrorTypes {
  'username',
  'password',
}

export enum loginFormsTypes {
  'login',
  'forgetPasswordCode',
  'resetConfirmed',
}

export enum activateErrorTypes {
  'username',
  'password',
}

export enum activateFormsTypes {
  'activate',
  'requestNewLink',
  'requestNewLinkConfirm',
}

export type alertObjectType = {
  type: 'error' | 'warning' | 'info' | 'success';
  message: string;
  timeout?: number;
};
